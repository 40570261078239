import { Component, OnInit, HostListener, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import packageInfo from 'package.json';
import { OutputService } from 'src/app/data/services/output.service';
import { ConfigService } from 'src/app/data/services/config.service';
import { OnboardingApiService } from "src/app/data/services/onboarding-api.service";
import { LaunchDarklyService } from 'src/app/data/services/launch-darkly/launch-darkly.service';
import { Subscription } from 'rxjs';
import { Page } from 'src/app/data/models/config';
import { DataService } from 'src/app/data/services/data.service';

type _associationDataAPIResponse = {
  associationId: number;
  associationName: string;
  imageContent: string;
  isSpecialUrlAllowed: boolean;
  errors: unknown;
}

type _PartialSignupAPIResponse = {
  partialSignupPayload: string;
  partialSignupLastPage: string;
  partialSignupNavigationHistory: string;
  partialSignupNavigationDepdendents: string;
  success: boolean;
  errors: unknown;
}

type _OOSignupAPIResponse = {
  customerKey: string;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  vendor: string;
  phoneNumber: string;
  optInStatus: boolean;
  optInDate: string;
  flow?: string;
  success: boolean;
  errors: unknown;
}

@Component({
  selector: 'app-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  currentYear: number = new Date().getFullYear();
  isITMEnabled: boolean;
  isOwnerOpEnabled:boolean;
  isEfsEnabled: boolean;
  isMercerEnabled = false;
  isBennettEnabled: boolean;
  routerEventsSubscription: Subscription;
  firstPage: {name: string};
  lastPathName: string;
  flagSubscription: any;  
  loading: boolean;
  loadingPartialSignup: boolean;
  skipValue: boolean = false;
  servicePlanScreen: Page;
  supportedOems: string [];
  OEM: string;
  loadingOOSignup: boolean;
  isPostPurchaseVehicleFlow: boolean;
  displayStepperComponent: boolean;
  displayBackButtonVehiclePage: boolean;
  isDisplayWelcomePage: boolean;
  flags: any;

  constructor(
    public outputService: OutputService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private onboardingApiService: OnboardingApiService,
    public launchDarklyService: LaunchDarklyService,
    private data: DataService
  ) {

    this.isITMEnabled = this.configService.isITMVendor();
    this.isOwnerOpEnabled =  this.configService.config.vendorId === "owner-op";
    this.isEfsEnabled = this.configService.isEFSVendor();
    this.isMercerEnabled = this.configService.config.vendorId === "mercer";
    this.isBennettEnabled = this.configService.isBennettVendor();
    }
  // so that canDectivateGuard will ask confirmation when refreshing or leaving the app
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if(this.configService.currentPage.name !== 'success') {
      $event.returnValue = true
    }
  }

  ngOnInit() {   
    this.subscribeToRouterEvents();
    if(this.isOwnerOpEnabled){
      this.checkStepperDisplay();
    }
    else {
      this.displayStepperComponent = true;
    }
    this.checkBackButtonDisplay();
    //Print current build version in console for reference.
    console.log(`Current build version:${packageInfo.version}`)
    /*
      This component reloads on when vendor id change in the route.
      So, remove previous pages from nav history which will prevent
      back button from navigating to previous vendor pages.
    */
    this.configService.removePreviousPagesFromNavHistory();

    // Disable Order total for Owner-op vendor till first vehicle is added.
    if(!this.isOwnerOpEnabled){
     this.configService.isFirstVehicleAdded = true;
    }  

    
    this.backButtonOverride();

      this.lastPathName = window.location.hash.split("/").pop();
      const URLParamsString = this.lastPathName.split('?')[1];
      const params = new URLSearchParams(URLParamsString);
      // lastPathName has value related to association name
      this.lastPathName = this.lastPathName.split("?")[0];
      this.skipValue = params.get('skip') === 'true';

      this.servicePlanScreen = this.configService.config.screens.find(
        (screenObj) => screenObj.name === 'coverage/service-plan'
      );
      this.supportedOems = this.servicePlanScreen.oems?.map(
        (oemObj) => oemObj.name
      );
      
      this.OEM = params.get('oem');
      const outputState = this.outputService.currentOutputState;
      if (this.OEM && this.supportedOems?.includes(this.OEM)) {
        this.configService.isOemPresentInURL = true;
        outputState.oem = this.OEM;
        this.outputService.updateState(outputState);
      }

      this.configService.prospectContactDetailsKey = params.get('prospect');

    if(this.route.snapshot?.firstChild?.routeConfig.path === 'coverage/service-plan') {
      this.route.queryParams.subscribe((params) => {
        // If OEM query param present in the URL, skip the OEM screen
        if (params.oem && this.supportedOems.includes(params.oem)) {
          const outputState = this.outputService.currentOutputState;
          outputState.oem = params.oem;
          outputState.oemId = this.configService.getCarMakeId(params.oem);
          this.outputService.updateState(outputState);
          const servicePlanIndex = this.configService.config.screens.findIndex(
            (screenObj) => screenObj.name === 'coverage/service-plan'
          );
          const screen = this.configService.config.screens[servicePlanIndex].name;
          this.configService.next(screen);
          this.firstPage = this.configService.config.screens[servicePlanIndex];
        } else if(params.saved) {
            this.configService.customerKey = params.saved;
            this.getPartialSignupData(params.saved).catch((err)=>{
              console.warn(err);
            });
        } else if(params.prospect) {
          this.configService.prospectContactDetailsKey = params.prospect;
          this.getOOSignupProspectData(params.prospect).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.firstPage = this.configService.config.screens[0];
        }
      });
    } else if(this.configService.prospectContactDetailsKey){
      this.getOOSignupProspectData(this.configService.prospectContactDetailsKey).catch((err)=>{
        console.warn(err);
      });
    } else {
      if (this.isOwnerOpEnabled  && this.lastPathName !== "welcome" && this.configService.config.screens.find(
        (screenObj) => screenObj.name != this.lastPathName
      )) {
        this.configService.associationName = this.lastPathName;
        this.getAssociationData(this.lastPathName);
      }
      this.partialSignupDataNavigation();
      
      if(!this.checkWelcomeScreenFlag()){
        this.updateWelcomeScreenDisplay();
      } else{
        this.firstPage = this.configService.config.screens[0];
      }
    }

    this.initFlags()
    this.isPostPurchaseVehicleFlow = this.checkPostPurchaseFlow();
    this.skipWelcomePage()
    this.navigateTofirstPage(this.firstPage?.name);
    this.loadFavIcon();
  } 

  initFlags(){
    if(!this.configService.lastSelectedFlowPage){
      this.configService.lastSelectedFlowPage = this.configService.getFlagData("post-purchase-vehicle");
    }
    if (this.configService.lastSelectedFlowPage === "noVehicleFlow") {
      this.configService.displayPartialSaveComponent = false;
    } else {
      this.configService.displayPartialSaveComponent = true;
    }

    let coverageFlagData = this.getCoverageFlagData();
    if(coverageFlagData) {
      this.configService.coverageFeatureFlag = coverageFlagData;
    } else {
      this.configService.coverageFeatureFlag = "new-service-plan";
    }

    if(!this.checkWelcomeScreenFlag()){
      this.updateWelcomeScreenDisplay();
    }

    this.configService.isBifurcationFlow = this.getBifurcationFlowFlagData();
  }

  checkWelcomeScreenFlag(){
    let flagValue = this.flags["Display-Welcome-Screen"];

    if(flagValue) {
      this.configService.welcomeScreenFlag = true;
      return true;
    } else {
      this.configService.welcomeScreenFlag = false;
      return false;
    }
  }

  getCoverageFlagData(): string{
    let flagData: string;
   
    if(this.flags){
      flagData = this.flags["new-coverag-page"];
    } else {
      flagData = "new-service-plan";
    }

    return flagData;
  }

  getBifurcationFlowFlagData(): boolean{
    let flagData: boolean;

    if(this.flags){
      flagData = this.flags["bifurcation-flow"];
    } else {
      flagData = false;
    }

    return flagData;
  }

  checkPostPurchaseFlow() : boolean {
    let isPurchaseFlow: boolean;

    if(this.flags){
      let flagData = this.flags["new-coverag-page"];
      if(flagData == "noVehicleFlow"){
        isPurchaseFlow = true;
      } else {
        isPurchaseFlow = false;
      }
    } else {
      isPurchaseFlow = false;
    }

    return isPurchaseFlow;
  }

  updateWelcomeScreenDisplay(){
    if(this.isOwnerOpEnabled || this.isBennettEnabled || this.isMercerEnabled){
      this.configService.removePreviousPagesFromNavHistory();
      this.firstPage = this.configService.config.screens[1];
      this.configService.pushHistory(this.configService.config.screens[1]);
    }
  }

  checkStepperDisplay(){
    this.routerEventsSubscription = this.data.displayStepper.subscribe((value: boolean) => {
      this.displayStepperComponent = value;
    });
  }

  checkBackButtonDisplay(){
    this.routerEventsSubscription = this.data.displayBackButtonVehicle.subscribe((value: boolean) => {
      this.displayBackButtonVehiclePage = value;
    });
  }

  ngAfterViewInit(): void {
    try {
      this.setMetaTitleForScreen();
    } catch (error) {
      console.warn(error);
    }
  }

  skipWelcomePage(){
    if(this.skipValue && !this.isEfsEnabled){
        if(this.configService.isOemPresentInURL && this.configService.config.vendorId === "itm"){
          this.firstPage = this.configService.config.screens[2];
            this.configService.pushHistory(this.configService.config.screens[2]);
        }
        else{
          this.firstPage = this.configService.config.screens[1];
          this.configService.next();
        }   
    }
  }

  async getAssociationData(shorterName: string): Promise<void> {
    try {
      const resp: _associationDataAPIResponse = await this.onboardingApiService.getAssociationData(shorterName);
      if (resp?.isSpecialUrlAllowed && resp?.associationId) {
        this.configService.setIsAssociationPresent(true);
        this.outputService.updateState({ attributionid: resp.associationId });
        this.configService.updateUrlParams();
        this.configService.validateAtoBAttributionID(resp.associationId.toString());
        if(resp.imageContent){
          this.configService.associationLogoImg = "data:image/png;base64," + resp.imageContent;
          this.loading = false;
        }
        this.configService.associationLogoName = resp.associationName;
        this.loading = false;
      } else {
        this.firstPage = this.configService.config.screens[0];        
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  openMenu() {
    const StepperRef: HTMLElement = document.getElementById("stepperMainContainer");
    if (StepperRef) {
      StepperRef.style.display = "block";
    }
  }
  closeMenu() {
    const StepperRef: HTMLElement = document.getElementById("stepperMainContainer");
    if (StepperRef) {
      StepperRef.style.display = "none";
    }
  }

  navigateTofirstPage(firstPage:string) {
    if (firstPage) {
      this.router.navigate([firstPage], {
        relativeTo: this.route,
        replaceUrl: true,
        queryParams: this.route.snapshot.queryParams,
      });
    }
  }
  public loadFavIcon() {
    const linkTag = document.createElement('link');
    linkTag.type = 'image/x-icon';
    linkTag.rel = 'icon';
    if(this.isITMEnabled){
      linkTag.href ='assets/images/ITM-favicon/favicon.png?v=321';}
    else if(this.isEfsEnabled || this.isOwnerOpEnabled){
      linkTag.href='assets/images/OO-EFS-favicon.png';}
    else{
      linkTag.href ='assets/images/bestpass-logo-white.png';}
     
    const linkFavicon = document.createElement('link');
    linkFavicon.rel = 'mask-icon';
    if(this.isITMEnabled){
      linkFavicon.href  ='assets/images/ITM-favicon/favicon.png?v=123';}
    else if(this.isEfsEnabled || this.isOwnerOpEnabled){
      linkFavicon.href ='assets/images/OO-EFS-favicon.png';}
    else{
      linkFavicon.href  ='assets/images/bestpass-logo-white.png?v=123';}

    const appleLinkTag = document.createElement('link');
    appleLinkTag.rel = 'apple-touch-icon';
    appleLinkTag.href = this.isITMEnabled ? 'assets/images/ITM-favicon/apple-touch-icon.png?v=123' : 'assets/images/apple-touch-icon.png?v=123';

    const appleLinkTag120 = document.createElement('link');
    appleLinkTag120.rel = 'apple-touch-icon';
    appleLinkTag120.href = this.isITMEnabled ? 'assets/images/ITM-favicon/favicon120x120.png?v=898' : 'assets/images/apple-touch-icon.png?v=123';

    const appleLinkTag152 = document.createElement('link');
    appleLinkTag152.rel = 'apple-touch-icon';
    appleLinkTag152.href = this.isITMEnabled ? 'assets/images/ITM-favicon/favicon152x152.png?v=898' : 'assets/images/apple-touch-icon.png?v=123';

    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(linkTag);
      document.head.appendChild(linkFavicon);
      document.head.appendChild(appleLinkTag);
      document.head.appendChild(appleLinkTag120);
      document.head.appendChild(appleLinkTag152);
    }
  }



  back(): void {
    this.deviceIDFalseVehicleInfo();
    let AddedVehicles = this.outputService.currentOutputState.vehicles;
    const lastVehicleIndex = AddedVehicles.length - 1;
    const trailerTypes = ["STT","FWT","TTT"];
    const lastAddedVehicle = AddedVehicles[lastVehicleIndex];
    const LastAddedVehicleType = lastAddedVehicle.type;
    if(this.configService.currentPage.name == "trailer/type" && trailerTypes.includes(LastAddedVehicleType)){
      if(!Object.keys(lastAddedVehicle).includes("id")){
        this.outputService.removeRecentVehicle();
      }
    }
    if(this.configService.currentPage.name == "trailer/details"){
        // setting isTrailerAdditionFlowComplete variable to true will avoid deleting
        this.configService.isTrailerAdditionFlowCompleted = false;
      /*
      Pop last record in the vehicles array as the flow would
      be incomplete due to the back button navigation
     */
      const allVehiclesState = this.outputService.currentOutputState.vehicles;
      const lastVehicle = allVehiclesState[allVehiclesState.length-1] || {};
      if (!this.configService.isTrailerAdditionFlowCompleted && lastVehicle.type === "TTT" && lastVehicle.addMoreState === "TrailerCombo" ) {

        if(Object.keys(lastVehicle).includes("id")){
          this.configService.isTrailerAdditionFlowCompleted = true;
        }
        else{
          this.outputService.removeRecentVehicle();
          this.configService.isTrailerAdditionFlowCompleted = true;
        }
      }
    }

    if(this.configService.currentPage.name == "vehicle/type") {
        // setting isVehicleAdditionFlowComplete variable to true will avoid deleting
        this.configService.isVehicleAdditionFlowComplete = false;
        this.outputService.AddNewVehicleFlow = false;
        const InitaialVehicle = this.outputService.currentOutputState.vehicles[0];
        const InitialVehicleProperties = Object.keys(InitaialVehicle);
        if(!Object.keys(lastAddedVehicle).includes("id") && lastAddedVehicle.key !== this.outputService.initialVehicleId ){
          this.outputService.removeRecentVehicle();
        }
  
        if( InitaialVehicle.type === "TTP" && !InitialVehicleProperties.includes("standardTrailerConfig")){
          this.outputService.updateVehicleState(this.outputService.initilalVehicleDetails,0);
        }
  
        if( InitaialVehicle.type !== "TTP" && !InitialVehicleProperties.includes("weight")){
          this.outputService.updateVehicleState(this.outputService.initilalVehicleDetails,0);
        }
        this.configService.previous();
      }
    else{
      this.configService.previous();
    }
  }

  deviceIDFalseVehicleInfo() {
    if (this.configService.currentPage.name === "vehicle/info") {
      this.configService.setIsDeviceidPrefill(false);
    }
  }

  home(e: MouseEvent): void {
    if ((e.target as HTMLElement).tagName === 'BUTTON') {
      const welcomeScreen = this.configService.config.screens.find(
        (screenObj) => screenObj.name === 'welcome'
      );
      if(this.configService.currentPage.name === 'success') {
        location.reload();
      }
      this.configService.next(welcomeScreen.name);
    }
  }

  subscribeToRouterEvents() {
    let vendorId = this.configService.config.vendorId;
    this.routerEventsSubscription = this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const vendorIdPrevious = this.configService.config.vendorId;
          if(vendorId === vendorIdPrevious){
            // Set meta title for screens on route change
            this.setMetaTitleForScreen();
          } else {
            vendorId = vendorIdPrevious;
            location.reload();
          }
        }
      });

      this.route.data.subscribe(data => {
        if(data.flag) {
          this.flags = data.flag;
          this.configService.flags = this.flags;
        }
      });
  }

  backButtonOverride() {
    if (this.route.snapshot.children.length == 0) {

      this.configService.browserBackButtonUserOverride = true

      this.firstPage = this.configService.config.screens[0];
      this.navigateTofirstPage(this.firstPage?.name);

      setTimeout(() => this.configService.browserBackButtonUserOverride = false, 0);
    }
  }

  comparePackageVersions(str1, str2) {
 // Extract the first two characters from each string
    if(str1 && str2){
      const slice1 = str1.slice(0, 4);
      const slice2 = str2.slice(0, 4);
    // Compare and return the result
    return slice1 === slice2;  
    }
    else{
    return false
    }
  }

  async getPartialSignupData(customerKey: string): Promise<void> {
    try {
      this.loadingPartialSignup = true;
      const resp: _PartialSignupAPIResponse = await this.onboardingApiService.getDataForPartialSignup(customerKey);
      if (resp.success) {
        const NavigationDependency = JSON.parse(resp?.partialSignupNavigationDepdendents);
        this.configService.buildVersion = NavigationDependency?.cofigDependencys?.buildVersion ? NavigationDependency?.cofigDependencys?.buildVersion:"";
        const isVersionsCompatable = this.comparePackageVersions(packageInfo.version, this.configService.buildVersion);
        this.configService.isAppVersionCompatible = isVersionsCompatable;
        this.configService.setPartialSignupData(resp);
        this.configService.setSalesForceId(customerKey);
          for (let screen of this.configService.config.screens) {
            if (screen.name == resp.partialSignupLastPage && isVersionsCompatable ) {
              this.configService.next(screen.name,true,true);
              this.loadingPartialSignup = false;
              this.data.triggerReloadStepper();

              if(screen.name == "contact/info"){
                this.data.triggerContactReload();
              }
              
              return;
            }
        }
        if(this.isITMEnabled){
          const fourthPage = this.configService.config.screens[3];
          this.configService.next(fourthPage.name,true,true);
        }
        else {
          switch (true) {
            case this.isOwnerOpEnabled:
              this.configService.next(this.configService.config.screens[1]?.name, true, true);
              break;
            case this.isEfsEnabled:
              this.configService.next(this.configService.config.screens[2]?.name, true, true);
              break;
            case this.isMercerEnabled:
              this.configService.next(this.configService.config.screens[3].name, true, true);
              break;
            default:
              this.configService.next(this.configService.config.screens[1].name, true, true);
              break;
          }
        }

        this.loadingPartialSignup = false;
        this.data.triggerReloadStepper();
      } else {
        console.warn("partial save error");
        this.firstPage = this.configService.config.screens[0];
        if (!this.isOwnerOpEnabled) {
          this.navigateTofirstPage(this.firstPage?.name);
        }
        this.loadingPartialSignup = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getOOSignupProspectData(customerKey: string): Promise<void> {
    try {
      this.loadingOOSignup = true;
      const resp: _OOSignupAPIResponse = await this.onboardingApiService.getDataForOOProspectSignup(customerKey);

      if(resp.success){
        this.loadingOOSignup = false;
        this.configService.setOOSignUpData(resp);
        this.configService.lastSelectedFlowPage = resp?.flow;

        if(this.isOwnerOpEnabled) {
          this.configService.next('contact/info',false,false);
        } else {
          this.navigateTofirstPage(this.firstPage?.name);
        }
        
        this.loadingPartialSignup = false;
        return;
      } else {
        console.warn("OO Signup error");
        this.firstPage = this.configService.config.screens[0];
        if (!this.isOwnerOpEnabled) {
          this.navigateTofirstPage(this.firstPage?.name);
        }
        this.loadingOOSignup = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  partialSignupDataNavigation() {
    this.route.queryParams.subscribe((params) => {
      if (params.saved) {
          this.configService.customerKey = params.saved;
          this.getPartialSignupData(params.saved).catch((err)=>{
            console.warn(err);
          });
      }
    });
  }

  setMetaTitleForScreen() {
    this.configService.setMetaTitleForScreen(this.route.firstChild?.snapshot);
  }

  ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
